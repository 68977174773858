import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "csv-tips"
    }}>{`CSV TIPS`}</h1>
    <h2 {...{
      "id": "-agregar-algo-al-final-de-cada-celda"
    }}>{`🎓 Agregar algo al final de cada celda.`}</h2>
    <p>{`cntrl + H para hacer Find & replace.
En google sheets, tildamos search by regular expressions.`}</p>
    <p>{`En find ponemos: (.)$`}</p>
    <p>{`en replace ponemos: \\1\\ + lo que querramos agregar, y al final "`}</p>
    <p>{`ejemplo, agregar .jpg, => \\1`}{`.`}{`jpg"`}</p>
    <h2 {...{
      "id": "-agregar-urls-para-csvs-mal-hechos"
    }}>{`🚀 Agregar URLS para CSVs MAL HECHOS`}</h2>
    <p>{`Si nos pasan un CSV como el de tate, que vienen asi en imagenes:`}</p>
    <p>{`AS1.jpg, AS2.jpg, AS3.jpg, AS4.jpg`}</p>
    <p>{`Deberian quedar asi:
`}<a parentName="p" {...{
        "href": "https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS1.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS2.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS3.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS4.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS5.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS6.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS7.PNG"
      }}>{`https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS1.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS2.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS3.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS4.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS5.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS6.PNG,https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/AS7.PNG`}</a></p>
    <p>{`Para que quede asi, hay que primero, hacer find and Replace, control H`}</p>
    <p>{`y remplezar las comas por la coma con el url`}</p>
    <p>{`FIND: ,
REPLACE: ,`}<a parentName="p" {...{
        "href": "https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/"
      }}>{`https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/`}</a></p>
    <p>{`Esto nos va a reemplazar todos menos el primer caracter.
Para el primer caracter usamos REGEX`}</p>
    <p>{`FIND: ^(.+?),`}</p>
    <p>{`esto busca el primer caracter.`}</p>
    <p>{`REPLACE: `}<a parentName="p" {...{
        "href": "https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/$1"
      }}>{`https://pruebadario.ecomerciar.com/wp-content/uploads/new-product-images/$1`}</a>{`,`}</p>
    <p>{`Importante, el $1 es lo que elegimos, y agregamos la coma al final, porque sino va desaparecer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      